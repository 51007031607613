import { mapActions, mapGetters } from "vuex"
import { CacheHelper } from "../../helpers/CacheHelper";
import { ErrorHelper } from "../../helpers/ErrorHelper";
import { SortableSearchMixin } from "./Searching/SortableSearchMixin";

export const BookingSearchMixin = {
    mixins: [SortableSearchMixin],
    data() {
        return {
            error: '',
            searchTerm: '',
            skip: 0,
            take: 10,
            returnDefault: true,
            timeoutId: null,
            statusId: 0,
            sortKey: 'createdDate',
            ascending: false,
            clientTypeId: 0
        }
    },
    computed: {
        ...mapGetters('BookingSearches', [
            'findBookingSearch'
        ]),
        bookingSearchModel() {
            return {
                searchTerm: this.searchTerm,
                sortKey: this.sortKey,
                ascending: this.ascending,
                returnDefault: this.returnDefault,
                skip: this.skip,
                take: this.take,
                statusId: this.statusId,
                clientTypeId: this.clientTypeId
            }
        },
        bookingSearchModelValid() {
            return !!this.searchTerm || this.returnDefault;
        },
        bookingSearchKey() {
            return CacheHelper.objectCacheKey(this.bookingSearchModel);
        },
        bookingSearch() {
            return this.findBookingSearch(this.bookingSearchKey);
        },
        bookings() {
            return !!this.bookingSearch ? this.bookingSearch.values : [];
        },
        searchingBookings() {
            return !!this.bookingSearch && this.bookingSearch.loading;
        },
        bookingResultsReady() {
            return !!this.bookingSearch && !this.searchingBookings;
        },
        moreBookings() {
            return this.bookings.length === this.take;
        },
        bookingPageNumber() {
            return 1 + (this.skip / this.take);
        }
    },
    watch: {
        searchTerm() {
            clearTimeout(this.timeoutId);
            this.timeoutId = setTimeout(() => {
                this.skip = 0;
                this.refreshBookingSearch();
            }, 500);
        },
        sortModel() {
            this.refreshBookingSearch();
        }
    },
    methods: {
        ...mapActions('BookingSearches', [
            'searchBookings'
        ]),
        refreshBookingSearch() {
            if (!this.bookingSearch || !!this.bookingSearch.retry) {
                this.searchAndCacheBookings();
            }
        },
        searchAndCacheBookings() {
            const self = this;
            self.error = '';
            if (!self.bookingSearchModelValid) {
                return;
            }
            self.searchBookings({ key: self.bookingSearchKey, payload: self.bookingSearchModel })
                .catch(error => self.error = ErrorHelper.getError(error));
        },
        next() {
            if (this.moreBookings) {
                this.skip += this.take;
                this.refreshBookingSearch();
            }
        },
        previous() {
            this.skip = Math.max(0, this.skip - this.take);
            this.refreshBookingSearch();
        },
        skipTo(skip) {
            this.skip = skip;
            this.refreshBookingSearch();
        }
    }
}
