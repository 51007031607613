import { mapState, mapActions } from "vuex"
import { ErrorHelper } from "../../helpers/ErrorHelper";
import { UserHelper } from "../../helpers/UserHelper"
import { Api } from '../../services/Api.js';

export const EmployeeAddMixin = {
    data() {
        return {
            error: '',
            empFirstName: '',
            empLastName: '',
            empSiteIds: [],
            empIsSiteAdmin: false,
            empCanDelete: false,
            empIsReadOnly: false,
            empHasPassword: false,
            empEmail: '',
            empPassword: '',
            userAddValidator: UserHelper.getUserAddValidator(),
            addedUser: null
        }
    },
    computed: {
        ...mapState('Employees', [
            'addingEmployee'
        ]),
        ...mapState('ApplicationEvents', [
            'userAddedEvent'
        ]),
        employeeAddModel() {
            return {
                firstName: this.empFirstName,
                lastName: this.empLastName,
                siteIds: this.empSiteIds,
                isSiteAdmin: this.empIsSiteAdmin,
                canDelete: this.empCanDelete,
                isReadOnly: this.empIsReadOnly,
                hasPassword: this.empHasPassword,
                email: this.empEmail,
                password: this.empPassword
            }
        },
        employeeAddModelValid() {
            return this.userAddValidator.isValid;
        },
        requiresPasswordEmail() {
            const model = this.employeeAddModel;
            return !!model.email && !model.hasPassword;
        }
    },
    watch: {
        userAddedEvent(user) {
            this.addedUser = user;
            this.sendPasswordSetEmail(user);
            this.refreshEmployeeModel();
        },
        empHasPassword(value) {
            if (!value) {
                this.empPassword = '';
            }
        }
    },
    methods: {
        ...mapActions('Employees', [
            'addEmployee'
        ]),
        refreshEmployeeModel() {
            this.empFirstName = '';
            this.empLastName = '';
            this.empSiteIds = [];
            this.empIsSiteAdmin = false;
            this.empCanDelete = false;
            this.empIsReadOnly = false;
            this.empHasPassword = false;
            this.empEmail = '';
            this.empPassword = '';
        },
        tryAddEmployee() {
            const self = this;
            self.userAddValidator.check(self.employeeAddModel);
            if (!self.employeeAddModelValid) {
                return;
            }
            self.error = '';
            self.addEmployee(self.employeeAddModel)
                .catch(error => self.error = ErrorHelper.getError(error));
        },
        sendPasswordSetEmail(user) {
            if (this.requiresPasswordEmail) {
                Api.post('Credentials/Set/Send', { userId: user.id })
                    .catch(() => { });
            }
        }
    }
}
