<template>
    <li @click="onClick">
        <a class="pointer" v-bind:class="{active: isSelectedPet}">
            <figure class="pet-photo p-0 mr-0">
                <i v-if="deceased" class="fa-duotone fa-solid fa-tombstone deceased-icon"></i>
                <img v-else-if="heroImage" :alt="heroImage.uploadedName" :src="heroImage.thumbnails.small" width="100">
                <template v-else>
                    <default-image></default-image>
                </template>
            </figure>
            <div class="d-flex flex-column w-100">
                <div class="pet-name">
                    {{petName}}
                </div>
                <div class="pet-type">
                    {{breedName}}
                </div>
            </div>
        </a>
    </li>
</template>

<script>
    import { BreedCacheMixin } from '../Shared/BreedCacheMixin.js';
    import DefaultPetImageSmall from '../Shared/Images/DefaultPetImageSmall.vue';
    import { ImageCacheMixin } from '../Shared/Images/ImageCacheMixin.js';

    export default {
        name: 'PetListItem',
        mixins: [BreedCacheMixin, ImageCacheMixin],
        props: {
            pet: Object,
            isSelectedPet: {
                value: false,
                type: Boolean
            }
        },
        computed: {
            ownerId() {
                return !!this.pet ? this.pet.id : '';
            },
            ownerTypeId() {
                return 3;
            },
            petName() {
                return !!this.pet ? this.pet.name : 'Unknown';
            },
            breedId() {
                return !!this.pet ? this.pet.breedId : '';
            },
            heroImage() {
                return this.images.length > 0 ? this.images[0] : null;
            },
            deceased() {
                return !!this.pet ? this.pet.deceased : false;
            }
        },
        methods: {
            onClick() {
                this.$emit('clicked', this.pet);
            },
            trySearchImages() {
                // do nothing
            },
            tryFetchBreed() {
                // do nothing
            }
        },
        components: {
            'default-image': DefaultPetImageSmall
        },
        created() {
            this.refreshBreed();
        }
    }
</script>
