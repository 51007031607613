<template>
    <div class="content fixed-action-btns-visible">
        <div class="row">
            <div class="col-12 col-md-auto">
                <pet-list v-model="selected" :client="client"></pet-list>
                <book-appointment v-if="selected" :pet="selected"></book-appointment>
                <div class="client-details-left sidenav">
                    <tags v-if="selected" :owner-id="selected.id" :owner-type-id="3"></tags>
                </div>
            </div>
            <pet-edit v-if="selected" v-model="editStatus" :client="client" :pet="selected"></pet-edit>
            <template v-else>
                <pet-add :client="client"></pet-add>
            </template>
        </div>
        <div class="fixed-action-btns action-btns fixed-action-btns--nonav">
            <div>
                <save-button @clicked="triggerSavePet" :status="status"></save-button>
                <button @click="cancel" class="btn btn-outline-secondary" type="button">{{$t('Cancel')}}</button>
            </div>
            <delete-button v-if="pet" type="button" data-target="#delete-pet-modal" data-toggle="modal"></delete-button>
        </div>
        <modal-dialog id="delete-pet-modal" :title="$t('Are you sure?')">
            <error-banner v-if="error" :message="error"></error-banner>
            <div role="alert" class="alert alert-warning  alert-icon">
                <div class="alert-content">
                    <p>{{$t('Are you sure you want to delete')}} {{petName}}?</p>
                </div>
                <button @click="tryDeletePet" v-bind:disabled="loadingPets" type="button" class="btn btn-danger" style="margin-right: 10px;">
                    <span v-if="loadingPets" class="inline-loader"></span>
                    <template v-else>
                        {{$t('Yes')}}
                    </template>
                </button>
            </div>
            <div class="modal-footer">
                <button ref="cancelButton" type="button" class="btn btn-secondary" data-dismiss="modal">{{$t('Cancel')}}</button>
            </div>
        </modal-dialog>
    </div>
</template>

<script>
    import { mapMutations } from 'vuex';
    import router from '../../routing';
    import PetOverviewPetList from './PetOverviewPetList.vue';
    import PetBookAppointment from './PetBookAppointment.vue';
    import TagSection from '../Shared/Tags/TagSection.vue';
    import PetAddV2 from './PetAdd/PetAddV2.vue';
    import PetEdit from './PetEdit.vue';
    import DeleteButton from '../Shared/DeleteButton.vue';
    import { PetDeleteMixin } from '../../mixins/Pets/PetDeleteMixin';
    import ModalDialog from '../Layout/ModalDialog.vue';
    import SaveButton from '../Shared/Buttons/SaveButton.vue';

    export default {
        name: 'PetOverview',
        mixins: [PetDeleteMixin],
        data() {
            return {
                selected: null,
                editStatus: {}
            }
        },
        props: {
            client: Object,
            addPressed: Number
        },
        computed: {
            clientId() {
                return !!this.client ? this.client.id : '';
            },
            pet() {
                return this.selected;
            },
            status() {
                return {
                    loading: this.loadingPets || this.editStatus.loading,
                    error: this.error || this.editStatus.error
                }
            },
            loading() {
                return this.loadingPets || this.editStatus.loading;
            }
        },
        watch: {
            addPressed() {
                this.selected = null;
            }
        },
        methods: {
            ...mapMutations('ApplicationEvents', [
                'triggerSavePet'
            ]),
            cancel() {
                this.$router.push({ name: 'clients' });
            },
            afterDeletedPet() {
                this.editStatus = {};
                this.$refs.cancelButton.click();
            }
        },
        components: {
            'pet-add': PetAddV2,
            'pet-edit': PetEdit,
            'pet-list': PetOverviewPetList,
            'book-appointment': PetBookAppointment,
            'tags': TagSection,
            'delete-button': DeleteButton,
            'modal-dialog': ModalDialog,
            'save-button': SaveButton
        }
    }
</script>
